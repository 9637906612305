<template>
  <v-navigation-drawer  dark v-model="drawer" :right="false" mobile-break-point="960" app width="260">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title" style="display: grid;justify-content: center;">
          <v-spacer/>
          <v-img src="../../../assets/logo2.png" width="52"></v-img>
           <v-spacer/>
        </v-list-item-title>
        <v-list-item-subtitle class="text-center mt-3">Sistema de gestão de conteúdo</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" link :to="item.to" @click="item.action">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>

import userService from '../../../service/userService'

export default {
  name: "Drawer",
  props:{
    drawer:Boolean
  },
  methods:{
    logout($event){
      userService.logout()
       this.$router.push({ name: `Login` });
    }
  },
  data: () => ({
    items: [
      // {
      //   icon: "mdi-view-dashboard",
      //   title: "Dashboard",
      //   to: "/cms",
      //   action:()=>null
      // },
      {
        icon: "mdi-newspaper",
        title: "Notícias",
        to: "/pages/news",
        action:()=>null
      },
      {
        icon: "mdi-tag",
        title: "Categorias",
        to: "/pages/category",
        action:()=>null
      },
      {
        icon: "mdi-image-multiple",
        title: "Galerias",
        to: "/pages/gallery",
        action:()=>null
      },
      {
        icon: "mdi-book",
        title: "Acordos e Convenções",
        to: "/pages/agreements",
        action:()=>null
      },
      {
        icon: "mdi-card-text",
        title: "Convênios",
        to: "/pages/covenants",
        action:()=>null
      },
      {
        icon: "mdi-message",
        title: "Contatos",
        to: "/pages/contacts",
        action:()=>null
      },
      {
        icon: "mdi-vote",
        title: "Votações",
        to: "/pages/voting",
        action:()=>null
      },
      {
        icon: "mdi-account-box",
        title: "Associados",
        to: "/pages/associate",
        action:()=>null
      },
      {
        icon: "mdi-account",
        title: "Usuários",
        to: "/pages/user",
        action:()=>null
      },
    ]
  }),
};
</script>

<style lang="sass">

</style>
